import { FeaturedTeamMembersData, IComponentProps } from "../../utils/types"
import TeamCard from "../cards/TeamCard"
import CardContainer from "../containers/CardContainer"

const FeaturedTeamMembers = ({ data }: IComponentProps<FeaturedTeamMembersData>) => {
  return (
    <div className={`featured-team flex flex-col gap-10 justify-center items-center w-full pb-20`}>
      {(data && data.title && data.title !== "") ?
        <h3 className="text-center text-primary-blue text-[2.5rem] md:text-[3rem] font-bold">
          {data.title}
        </h3>
        :
        null
      }
      <div className="members w-full flex flex-row flex-wrap justify-center gap-20 px-20">
        {data && data.teamMembers && data.teamMembers.data.map((item, index) => (
          <TeamCard key={index} data={item.attributes} />
        ))}
      </div>
    </div>
  )
}

export default FeaturedTeamMembers
