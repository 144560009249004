import CardContainer from "../containers/CardContainer"
import VentureCard from '../cards/VentureCard'
import { VentureListData, IComponentProps } from '../../utils/types'

const VentureList = ({ data }: IComponentProps<VentureListData>) => {
  return (
    <div className="card-container px-2 md:px-20 py-12">
      {data.organisations.data.sort((a, b) => (a.attributes.displayOrder > b.attributes.displayOrder) ? 1 : -1).map((item, index) => (
        <VentureCard data={item.attributes} key={index} />
      ))}
    </div>
  )
}

export default VentureList