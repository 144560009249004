
import { DoubleSliderData, StrapiComponent, ExampleComponentData, DataBlockData, ProcessData, FeaturedVenturesData, DataListData, EngagementListData, DoubleBlockData, VentureListData, TeamMemberListData, MilestoneListData, ResourceListData, ResourceGridData, ResourceData, ImageDataBlockData, JobBoardData, FeaturedTeamMembersData, PromosBlockData, FeaturedLinkData, GreenhouseJob, FoundersData, TripleModuleData, AccordionData, DataFormData, BanneredContainerData, ImageGridData, ScaledStepsData, ResourcePicksData, FilesSectionData, AirtableFormData, QuotesSliderData, InfoGalleryData } from '../utils/types'
import ExampleComponent from '../components/example'
import DataBlock from './containers/DataBlock'
import Process from '../components/business/Process'
import VentureFeatures from '../components/business/VentureFeatures'
import DataPointsList from '../components/containers/DataPointsList'
import EngageUs from '../components/containers/EngageUs'
import DoubleBlock from './containers/DoubleBlock'
import VentureList from './sections/VentureList'
import TeamList from './sections/TeamList'
import MilestoneList from './sections/MilestoneList'
import ArticleModule from './sections/ArticleModule'
import FeaturedArticles from './sections/FeaturedArticles'
import ImageDataBlock from './containers/ImageDataBlock'
import JobBoard from './sections/JobBoard'
import FeaturedTeamMembers from './sections/FeaturedTeamMembers'
import PromosList from './containers/PromosList'
import FeaturedLink from './sections/FeaturedLink'
import Downloadables from './sections/Downloadables'
import Founders from './containers/Founders'
import TripleModule from './containers/TripleModule'
import Accordion from './containers/Accordion'
import DataForm from './DataForm'
import BanneredContainer from './containers/BanneredContainer'
import ImageGrid from './ImageGrid'
import ScaledSteps from './ScaledSteps'
import DoubleSlider from './DoubleSlider'
import ArticlePicks from './ArticlePicks'
import ArticleCard from './cards/ArticleCard'
import LatestArticles from './LatestArticles'
import AirtableForm from './AirtableForm'
import QuotesSlider from './QuotesSlider'
import InfoGallery from './InfoGallery'

interface SectionsProps {
    sections: StrapiComponent[] | null,
    articles?: Array<ResourceData>,
    jobs?: Array<GreenhouseJob> | null,
    slug?: string
}

interface SectionProp {
    section: StrapiComponent,
    articles?: Array<ResourceData>,
    jobs?: Array<GreenhouseJob> | null,
    slug?: string
}

const Section = ({ section, articles, jobs, slug }: SectionProp) => {

    let componentData;

    switch (section.__component) {
        case 'component.airtable-form':
            componentData = section as AirtableFormData
            return <AirtableForm data={componentData} />
        case 'component.example-component':
            componentData = section as ExampleComponentData
            return <ExampleComponent data={componentData} />
        case 'component.data-block':
            componentData = section as DataBlockData
            return <DataBlock data={componentData} />
        case 'component.methodology':
            componentData = section as ProcessData
            return <Process data={componentData} />
        case 'component.featured-ventures':
            componentData = section as FeaturedVenturesData
            return <VentureFeatures data={componentData} />
        case 'component.data-list':
            componentData = section as DataListData
            return <DataPointsList data={componentData} />
        case 'component.engagement-list':
            componentData = section as EngagementListData
            return <EngageUs data={componentData} />
        case 'component.double-block':
            componentData = section as DoubleBlockData
            return <DoubleBlock data={componentData} />
        case 'component.all-ventures':
            componentData = section as VentureListData
            return <VentureList data={componentData} />
        case 'component.team-members':
            componentData = section as TeamMemberListData
            return <TeamList data={componentData} />
        case 'component.milestone-list':
            componentData = section as MilestoneListData
            return <MilestoneList data={componentData} />
        case 'component.featured-articles':
            componentData = section as ResourceListData
            return <ArticleModule data={componentData} />
        case 'component.article-grid':
            componentData = {...section, resources: articles} as ResourceGridData
            return <FeaturedArticles data={componentData} />
        case 'component.image-data-block':
            componentData = section as ImageDataBlockData
            return <ImageDataBlock data={componentData} />
        case 'component.job-board':
            componentData = {
                ...section,
                jobs: jobs
            } as JobBoardData
            return <JobBoard data={componentData} />
        case 'component.featured-team-members':
            componentData = section as FeaturedTeamMembersData
            return <FeaturedTeamMembers data={componentData} />
        case 'component.promos-block':
            componentData = {
                ...section,
                contain: (slug && slug === 'library')
            } as PromosBlockData
            return <PromosList data={componentData} />
        case 'component.featured-link':
            componentData = section as FeaturedLinkData
            return <FeaturedLink data={componentData} />
        case 'component.founders':
            componentData = section as FoundersData
            return <Founders data={componentData} />
        case 'component.triple-module':
            componentData = section as TripleModuleData
            return <TripleModule data={componentData} />
        case 'component.quotes-slider':
            componentData = section as QuotesSliderData
            return <QuotesSlider data={componentData} />
        case 'component.accordion':
            componentData = section as AccordionData
            return <Accordion data={componentData} />
        case 'component.general-form':
            componentData = section as DataFormData
            return <DataForm data={componentData} />
        case 'component.bannered-container':
            componentData = section as BanneredContainerData
            return <BanneredContainer data={componentData} />
        case 'component.image-grid':
            componentData = section as ImageGridData
            return <ImageGrid data={componentData} />
        case 'component.scaled-steps':
            componentData = section as ScaledStepsData
            return <ScaledSteps data={componentData} />
        case 'component.double-slider':
            componentData = section as DoubleSliderData
            return <DoubleSlider data={componentData} />
        case 'component.picks':
            componentData = section as ResourcePicksData
            return <ArticlePicks data={componentData} />
        case 'component.link-row':
            componentData = section as InfoGalleryData
            return <InfoGallery data={componentData} />
        case 'component.latest':
            componentData = {
                ...section,
                resources: articles
            } as ResourceGridData
            return <LatestArticles data={componentData} />
        case 'component.all-articles':
            componentData = {
                ...section, 
                resources: articles,
                bottomPadding: true,
                disablePopular: true,
                pageLimit: 9
            } as ResourceGridData
            return <FeaturedArticles data={componentData} />
        default:
            return <></>
    }
}


const Sections = ({ sections, articles, jobs, slug }: SectionsProps) => {
    
    if(!sections) {
        return <></>
    }

    return (
        <div id='main-content'>
            {sections && sections.map((section, index) => {
                return <Section slug={slug} key={index} section={section} articles={articles} jobs={jobs} />
            })}
        </div>
    )
}


export default Sections