import ThemeMap from '../styles/themes'
import { DataBlockData, DoubleBlockData, DoubleSliderData, IComponentProps } from '../utils/types'
import DataBlock from './containers/DataBlock'
import Image from 'next/image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from "swiper"
import { useSwiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import "swiper/css/pagination"


const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const DoubleSlider = ({ data }: IComponentProps<DoubleSliderData>) => {
  return (
    <div className={`w-full p-0 m-0`}>
      <Swiper
        slidesPerView={1}
        spaceBetween={5}
        loop={true}
        autoplay={{
          delay: 9500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {data.slides.map((item, index) => (
          <SwiperSlide key={index}>
            <VerticalDoubleBlock data={item as DoubleBlockData} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default DoubleSlider

interface VerticalDoubleBlockProps {
  data: DoubleBlockData
}

const VerticalDoubleBlock = ({ data }: VerticalDoubleBlockProps) => {

  const firstBlock = {
    title: data.titleBlockOne,
    content: data.contentBlockOne,
    theme: data.themeBlockOne || 'white',
    bgImage: data.bgImageBlockOne,
    callToAction: data.ctaBlockOne,
    sectionTitle: data.sectionTitleBlockOne,
    narrowHeight: false
  } as DataBlockData

  const secondBlock = {
    title: data.titleBlockTwo,
    content: data.contentBlockTwo,
    theme: data.themeBlockTwo || 'white',
    bgImage: data.bgImageBlockTwo,
    callToAction: data.ctaBlockTwo,
    sectionTitle: data.sectionTitleBlockTwo,
    narrowHeight: false
  } as DataBlockData

  

  const firstTheme = ThemeMap[firstBlock.theme]
  const secondTheme = ThemeMap[secondBlock.theme]

  return (
    <div className={`double-slide w-full flex flex-col h-[70vh] md:h-[71vh] text-xl md:text-3xl text-center font-semibold`}>
      <div className={`relative px-10 md:px-20 lg:px-48 data-block-${firstBlock.bgImage?.data ? 'gray' : firstBlock.theme} flex items-center justify-center h-[60%] ${firstBlock.bgImage?.data ? 'bg-soft-gray' : firstTheme.primary} ${firstBlock.bgImage?.data ? 'text-primary-blue' : firstTheme.textPrimary}`}>
        {firstBlock.bgImage?.data && <Image alt={firstBlock.bgImage.data.attributes.alternativeText} src={ImageEndpoint + firstBlock.bgImage.data.attributes.url} layout='fill' objectFit='cover' className='bg-image' />}
        <div className='z-[99]' dangerouslySetInnerHTML={{__html: firstBlock.content!}}></div>
      </div>
      <div className={`relative px-10 md:px-20 lg:px-48 data-block-${secondBlock.bgImage?.data ? 'gray' : secondBlock.theme} flex items-center justify-center h-[40%] ${secondBlock.bgImage?.data ? 'bg-soft-gray' : secondTheme.primary} ${secondBlock.bgImage?.data ? 'text-primary-blue' : secondTheme.textPrimary}`}>
        {secondBlock.bgImage?.data && <Image alt={secondBlock.bgImage.data.attributes.alternativeText} src={ImageEndpoint + secondBlock.bgImage.data.attributes.url} layout='fill' objectFit='cover' className='bg-image' />}
        <div className='z-[99]' dangerouslySetInnerHTML={{__html: secondBlock.content!}}></div>
      </div>
    </div>
  )
}