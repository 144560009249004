import React from 'react'
import Script from 'next/script'
import { AirtableFormData, IComponentProps } from '../utils/types'

const AirtableForm = ({ data }: IComponentProps<AirtableFormData>) => {
  return (
    <div>
      <Script src={data.scriptSrc}></Script>
      <iframe 
        className='airtable-embed airtable-dynamic-height' 
        src={data.iframeSrc}
        frameBorder='0' 
        onWheel={undefined} 
        width='100%' 
        height='4081.75' 
        style={{background: 'transparent', border: '1px solid #ccc'}}
      ></iframe>
    </div>
  )
}

export default AirtableForm