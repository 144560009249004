import Image from "next/image"
import Link from "next/link"
import { IComponentProps, TeamMemberData } from "../../utils/types"

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const TeamCard = ({ data }: IComponentProps<TeamMemberData>) => {
  return (
    <Link href={`/team/${data.slug}`}>
      <a>
        <div className={`team-card flex flex-col gap-3`}>
          {data.photo.data &&
            <div className="relative" role="img">
              <Image 
                src={ImageEndpoint + data.photo.data.attributes.url} 
                alt={data.photo.data.attributes.alternativeText}
                height={data.photo.data.attributes.height}
                width={data.photo.data.attributes.width}
                layout="fill"
                objectFit="cover"
              />
            </div>
          }
          <div className="card-info text-primary-blue">
            <h3 className="font-bold text-3xl">{data.name}</h3>
            <h4 className="">{data.jobTitle}</h4>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default TeamCard