import ThemeMap from '../../styles/themes'
import { IComponentProps, PromoData } from '../../utils/types'
import Link from 'next/link'
import NewsLetter from '../sections/NewsLetter'

const PromosBlock = ({ data }: IComponentProps<PromoData>) => {

    const currentTheme = ThemeMap[data.theme || "white"]

    return (
        <div 
            className={`flex flex-col gap-3 justify-center ${data.addSubscribe === true ? 'bg-primary-blue' : currentTheme.primary} ${data.addSubscribe === true ? 'text-white' : currentTheme.textPrimary} px-10 py-16 h-full w-full`} 
            id={(data.sectionId && data.sectionId !== "") ? data.sectionId : undefined}
        >
            {data.addSubscribe !== true ? 
                <>
                    {(data.title && data.title !== "") ? 
                        <div className='text-2xl font-bold' dangerouslySetInnerHTML={{__html: data.title}}>
                        </div>
                        :
                        null
                    }
                    {(data.content && data.content !== "") ? 
                        <div className='text-xl font-medium' dangerouslySetInnerHTML={{__html: data.content}}>
                        </div>
                        :
                        null
                    }
                    {data.callToAction ? 
                        <Link href={data.callToAction.url}>
                            <a  {...data.callToAction.newTab ? {target: '_blank', rel: 'noreferrer'} : {}} className={`text-sm select-none rounded-full uppercase w-fit px-4 py-1 btn-${data.callToAction.theme}-${data.callToAction.color}`}>
                                {data.callToAction.text}
                            </a>
                        </Link>
                        :
                        null
                    }
                </>
                :
                <>
                    <h3 className='text-3xl font-bold mb-10'>Join Our Mailing List</h3>
                    <NewsLetter />
                </>
            }
        </div>
    )
}

export default PromosBlock