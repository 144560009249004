import React from 'react'
import { ArrayItemObject, ArticleType, IComponentProps, ResourceData, ResourcePicksData } from '../utils/types'
import ArticleCard from './cards/ArticleCard'
import useWindowDimensions from "./../utils/hooks"

const ArticlePicks = ({ data }: IComponentProps<ResourcePicksData>) => {
  const { width } = useWindowDimensions()
  function dateSort(a: ArrayItemObject<ResourceData>, b: ArrayItemObject<ResourceData>) {
    const dateA = a.attributes.creationDate// || a.publishedAt || a.createdAt
    const dateB = b.attributes.creationDate// || b.publishedAt || b.createdAt
    if(dateA == dateB){
      return 0
    }
    return dateA < dateB ? 1 : -1
  }

  const getLatests = () => {
    return data.resources.data
      .filter(x => x.attributes.creationDate)
      .sort(dateSort)
  }

  return (
    <div className={`article-picks flex flex-col gap-5 p-5 md:px-20 md:py-20 md:pb-10`}>
      {((data.title || data.subtitle) || true) && 
        <div>
          <h3 className='text-[2.4rem] font-[700] text-primary-blue'>
            {data.title}
          </h3>
          <h4 className='text-primary-blue text-[1.3rem]'>
            {data.subtitle}
          </h4>
        </div>
      }
      <div className='article-picks-featured'>
        <ArticleCard data={getLatests()[0].attributes} type={ArticleType.FEATURED_HORIZONTAL} />
      </div>
      <div className='flex flex-col md:flex-row gap-5'>
        {getLatests().map((item, index) => {
            if (index !== 0 && index < 4) { 
              return (
                <div key={index} className='article-picks-row w-full md:w-1/3 pb-5 md:pb-0'>
                  <ArticleCard data={item.attributes} type={(width && width <= 768) ? ArticleType.REDUCED : ArticleType.NORMAL} />
                </div>
              )
            }
        })}
      </div>
    </div>
  )
}

export default ArticlePicks