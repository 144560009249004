import React from 'react'
import { IComponentProps, QuoteData, QuotesSliderData } from '../utils/types'


import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from "swiper"

import 'swiper/css'
import 'swiper/css/navigation'
import "swiper/css/pagination"

const QuotesSlider = ({ data }: IComponentProps<QuotesSliderData>) => {
    return (
        <div className='w-full px-10 py-5'>
            <div className={`w-full p-0 m-0`}>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={20}
                    loop={true}
                    autoplay={{
                        delay: 9500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    <SwiperSlide>
                        <div className='w-full h-[350px] flex flex-col justify-center items-center px-10'>
                            <div>
                                <h3 className='text-primary-blue text-[6rem] font-bold leading-[1] w-fit'>
                                    {data.title}
                                </h3>
                                <h4 className='text-primary-green text-[3rem] font-bold leading-[1]'>
                                    {data.subtitle}
                                </h4>
                            </div>
                        </div>
                    </SwiperSlide>
                    {data.quotes.map((item, index) => (
                        <SwiperSlide key={index}>
                            <QuoteSlide quote={item} position={index} key={index} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default QuotesSlider

interface QuoteProps {
    quote: QuoteData,
    position: number
}

const QuoteSlide = ({ quote, position }: QuoteProps) => {
    return (
        <div className={`p-0 ${position % 2 === 0 ? 'mb-[50px]' : 'mt-[50px]'}`}>
            <figure 
                className={`w-full p-5 text-white text-center flex flex-col h-[300px] justify-between ${position % 2 === 0 ? 'bg-primary-blue' : 'bg-primary-green'}`}
            >
                <blockquote className='text-[1.5rem] leading-[1.2]' style={{fontWeight: '200'}}>
                    <q>{quote.quote}</q>
                </blockquote>
                <figcaption className='flex flex-col justify-center'>
                    {quote.authorName}
                    <cite>
                        {quote.authorTitle}
                    </cite>
                </figcaption>
            </figure>
        </div>
    )
}