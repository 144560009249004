import React from 'react'
import { IComponentProps, PromosBlockData } from '../../utils/types'
import PromosBlock from './PromoBlock'

const PromosList = ({ data }: IComponentProps<PromosBlockData>) => {
  return (
    <div className={`w-full ${data.contain ? 'md:px-20' : ''}`}>
      <div className={`flex flex-col md:flex-row ${data.contain ? 'md:shadow-lg' : ''}`}>
        <div className={`w-full ${data.highlighted === 'left' ? 'md:w-2/3' : 'md:w-1/3'}`}>
          {data.promo_1.data ?
            <PromosBlock data={data.promo_1.data.attributes} />
            :
            <div className='bg-primary-green-60 h-full w-full'>
            </div>
          }
        </div>
        <div className={`w-full ${data.highlighted === 'left' ? 'md:w-1/3' : 'md:w-2/3'}`}>
          {data.promo_2.data ?
            <PromosBlock data={data.promo_2.data.attributes} />
            :
            <div className='bg-primary-green-light h-full w-full'>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PromosList
