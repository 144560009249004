import React from 'react'
import DataForm from '../DataForm'
import Image from 'next/image'
import { BanneredContainerData, IComponentProps } from '../../utils/types'
import { IoIosArrowDown } from 'react-icons/io'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const BanneredContained = ({ data }: IComponentProps<BanneredContainerData>) => {
  return (
    <div className={`flex flex-col md:flex-row w-full md:h-[90vh]`}>
      <div className='w-full md:w-1/2 relative h-[90vh] md:h-full'>
        <Image 
          src={ImageEndpoint + data.featuredImage.data.attributes.url} 
          alt={data.featuredImage.data.attributes.alternativeText}
          layout='fill'
          objectFit='cover'
        />
        <a
          className='page-cta absolute flex md:hidden justify-center bottom-32 items-center w-8 h-8 border-solid border-2 border-white rounded-full hover:bg-white hover:fill-black cursor-pointer'
          onClick={(e) => {
              let mainContent = document.getElementById('bannered_form')
              e.preventDefault()
              mainContent && mainContent.scrollIntoView({ behavior: 'smooth' })
          }}
        >
          <IoIosArrowDown color='#1F1A33' size={43} />
        </a>
      </div>
      <div id='bannered_form' className='w-full md:w-1/2'>
        <DataForm data={data.childElement} takeFullSpace={true} />
      </div>
    </div>
  )
}

export default BanneredContained