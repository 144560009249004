import Image from "next/image"
import Link from "next/link"
import { AiOutlineArrowRight } from "react-icons/ai"
import { FoundersData, IComponentProps } from "../../utils/types"

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const Founders = ({ data }: IComponentProps<FoundersData>) => {
  return (
    <div className="founders founders-white bg-light-gray flex flex-col gap-16 px-10 md:px-32 lg:px-40 xl:px-60 py-20 md:py-28 text-primary-blue">
      {(data.title && data.title !== "") &&
        <div className="max-w-6xl" dangerouslySetInnerHTML={{__html: data.title}}>
        </div>
      }
      <div className="flex flex-col md:flex-row gap-8 md:gap-5 justify-start md:justify-between">
        {data.foundersList.data.map((item, index) => (
          <div key={index} className={((index % 2) !== 0) ? "flex flex-row-reverse md:flex-row gap-5 w-full md:w-1/2" : "flex gap-5 w-full md:w-1/2"}>
            {item.attributes.photo.data && 
              <div className="founder-image relative w-1/2 aspect-[1/1.3] overflow-hidden rounded-md">
                <Image 
                  src={ImageEndpoint + item.attributes.photo.data.attributes.url} 
                  alt={item.attributes.photo.data.attributes.alternativeText}
                  layout="fill" 
                  objectFit="cover" 
                />
              </div>
            }
            <div className="w-1/2 flex flex-col">
              <h4 className="text-2xl md:text 2xl lg:text-4xl font-bold">
                {item.attributes.name}
              </h4>
              <span className="text-lg md:text-2xl">
                {item.attributes.jobTitle}
              </span>
              {item.attributes.slug && <Link href={`/team/${item.attributes.slug}`}>
                <a className="flex gap-1 items-center font-base mt-auto hover:underline">
                  Read Bio <AiOutlineArrowRight color="#1F1A33" size={15} />
                </a>
              </Link>}
            </div>
          </div>
        ))}
      </div>
      {data.callToAction &&
        <Link href={data.callToAction.url}>
            <a  {...data.callToAction.newTab ? {target: '_blank', rel: 'noreferrer'} : {}} className={`self-center flex gap-1 items-center uppercase px-4 py-2 text-[1.2rem] select-none btn-rounded btn-${data.callToAction.theme}-${data.callToAction.color}`}>
              {data.callToAction.text}
              <AiOutlineArrowRight color="#1F1A33" size={15} />
            </a>
        </Link>
      }
    </div>
  )
}

export default Founders