


import {IComponentProps, ExampleComponentData} from '../utils/types';

import Image from 'next/image'
import { getStrapiMedia } from '../utils/api'

const ExampleComponent = ({ data }: IComponentProps<ExampleComponentData>) => {

    let singleMedia = data.mediaSingle?.data.attributes

    return (
        <>
            <h1>{data.title}</h1>
            <dl>
                <dt>Rich text</dt>
                <dd>{data.rich}</dd>
                <dt>Number</dt>
                <dd>{data.number}</dd>
                <dt>Date</dt>
                <dd>{data.date}</dd>
                <dt>Bool</dt>
                <dd>{data.bool}</dd>
                <dt>Enum</dt>
                <dd>{data.enum}</dd>
                <dt>Media Single</dt>
                <dl>
                    {
                        singleMedia && Object.entries(singleMedia.formats).map(([key, format], i) => {
                            const url = getStrapiMedia(format.url)
                            return (
                                <span key={i}>
                                    <dt>{key}</dt>
                                    <dd>
                                        {
                                            url ? <Image src={url} width={format.width} height={format.height} alt={singleMedia?.alternativeText} /> : null
                                        }
                                    </dd>
                                </span>
                            )
                        })
                    }
                </dl>
            </dl>
        </>
    )
}

export default ExampleComponent