import DataBlock from "./DataBlock"
import { IComponentProps, DoubleBlockData, DataBlockData } from "../../utils/types"

const DoubleBlock = ({ data }: IComponentProps<DoubleBlockData>) => {

  const isThereOnlyImage = (dataBlock: DataBlockData) => {
    return dataBlock.bgImage?.data && (dataBlock.title === undefined || dataBlock.title === null || dataBlock.title === "") && (dataBlock.content === undefined || dataBlock.content === null || dataBlock.content === "") && (dataBlock.callToAction === null || dataBlock.callToAction === undefined)
}

  const firstBlock = {
    title: data.titleBlockOne,
    content: data.contentBlockOne,
    theme: data.themeBlockOne,
    bgImage: data.bgImageBlockOne,
    callToAction: data.ctaBlockOne,
    sectionTitle: data.sectionTitleBlockOne,
    narrowHeight: true
  } as DataBlockData

  const secondBlock = {
    title: data.titleBlockTwo,
    content: data.contentBlockTwo,
    theme: data.themeBlockTwo,
    bgImage: data.bgImageBlockTwo,
    callToAction: data.ctaBlockTwo,
    sectionTitle: data.sectionTitleBlockTwo,
    narrowHeight: true
  } as DataBlockData

  return (
    <div className={`subscribe flex flex-col-reverse md:flex-row`} id={(data.sectionId && data.sectionId !== "") ? data.sectionId : undefined}>
      <div className={`w-full ${data.highlightedBlock === "left" ? 'md:w-1/2 lg:w-3/5' : 'md:w-1/2 lg:w-2/5'}`}>
        <DataBlock data={{...firstBlock, alignImage: 'left'}} />
      </div>
      <div className={`w-full ${data.highlightedBlock === "left" ? 'md:w-1/2 lg:w-2/5' : 'md:w-1/2 lg:w-3/5'}`}>
        <DataBlock data={{...secondBlock, alignImage: 'right'}} />
      </div>
    </div>
  )
}

export default DoubleBlock