import { useState } from 'react'
import Link from 'next/link'
import Image from "next/image"
import { IComponentProps, OrganisationData } from '../../utils/types'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

interface VentureCardProps {
  data: OrganisationData,
  noShift?: boolean
}

const VentureCard = ({ data, noShift }: VentureCardProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false)
  return (
    <Link href={`/ventures/${data.slug}`} passHref>
      <a>
        <div
          className={`h-full z-[50] relative venture-card flex ${!noShift ? 'flex-row md:flex-col' : 'flex-col'} items-center bg-white cursor-pointer shadow-md text-gray-500 hover:text-white`}
          style={noShift ? { border: 'solid 1px #e0dede' } : undefined}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {isHovering && data.featuredImage.data ? 
            <Image 
              src={ImageEndpoint + data.featuredImage.data.attributes.url} 
              alt={data.featuredImage.data.attributes.alternativeText} 
              height={data.featuredImage.data.attributes.height}
              width={data.featuredImage.data.attributes.width}
              layout="fill"
              objectFit='cover'
              className='featuredImage'
            />  
            :
            null
          }
          <div className={`card-header aspect-[1/1] ${!noShift ? 'w-1/2 md:w-full' : 'w-full'} flex flex-col items-center justify-center`}>
            {!isHovering && (data.isotype.data ? 
              <div className="venture-logo w-1/2 aspect-1/1 relative z-[99]">
                <Image
                  src={`${ImageEndpoint}${data.isotype.data.attributes.url}`}
                  alt={data.isotype.data.attributes.alternativeText}
                  priority={true}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
              : (data.logo.data &&
              <div className="venture-logo relative h-3/4 z-[99]">
                <Image
                  src={`${ImageEndpoint}${data.logo.data.attributes.url}`}
                  alt={data.logo.data.attributes.alternativeText}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            ))}
            <h3 className={`text-${isHovering ? "2xl lg:3xl text-white" : "2xl"} font-bold uppercase pt-2 px-2 text-center z-[99]`}>
              {isHovering ? data.industrySection : data.title}
            </h3>
            {noShift && <span className='text-xl'>{data.industrySection}</span>}
          </div>
          {!noShift && <div className="card-body flex-1 p-5 z-[99]">
            <p className="text-lg font-medium">
              {data.tagline}
            </p>
          </div>}
        </div>
      </a>
    </Link>
  )
}

export default VentureCard
