import ThemeMap from '../../styles/themes'
import { DataListData, IComponentProps } from '../../utils/types'

const DataPointsList = ({ data }: IComponentProps<DataListData>) => {
  const currentTheme = ThemeMap[data.theme || 'white']
  return (

    <div className={`data-points ${(data.highlightValues && data.theme === 'white') ? 'data-points-highlight' : ''} relative px-16 py-12 ${data.addNegativeSpace ? 'md:py-20' : 'md:py-7'} ${currentTheme.primary} ${currentTheme.textPrimary}`}>
      <div className="container mx-auto">
        <ul className="w-full flex flex-col md:flex-row justify-around gap-5 flex-wrap">
          {data.dataPoints.map((item, index) => (
            <li className="flex flex-col items-center" key={index}>
              <h3 className={`${data.highlightValues ? 'text-[3rem]' : 'text-4xl'} uppercase ${data.highlightValues ? currentTheme.textSecondary : ''}`}>{item.value}</h3>
              <h4 className={`text-[1.4rem] ${data.highlightValues ? 'md:text-[1.8rem]' : ''} font-semibold`}>{item.metric}</h4>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DataPointsList
