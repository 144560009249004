import React from 'react'
import Link from 'next/link'
import { AiOutlineArrowRight, AiOutlineDownload } from 'react-icons/ai'
import { IComponentProps, InfoGalleryData } from '../utils/types'

const InfoGallery = ({ data }: IComponentProps<InfoGalleryData>) => {

    const getClasses = () => {
        const layout = !!(data.links?.length === 1) && 'md:flex-row md:justify-between'
        return `info-gallery flex flex-col p-10 md:p-20 ${layout} md:px-20 lg:px-40 lg:py-28 gap-10 bg-light-gray`
    }

    return (
        <div className={getClasses()}>
            <div 
                dangerouslySetInnerHTML={{__html: data.title}}
                data-length={data.links?.length} 
                className={`title text-primary-blue font-bold ${data.links?.length > 1 ? 'md:w-full' : 'md:w-[clamp(300px,60%,900px)]'}`}>
            </div>
            <div className={`flex flex-col justify-center items-center md:flex-row gap-10 ${data.links?.length > 1 ? 'md:w-full' : 'md:w-[clamp(350px,40%,600px)]'} `}>
                {data.links?.map((item, index) => (
                    <div 
                        key={index} 
                        data-number={index} 
                        className={`card flex flex-col justify-between gap-8 w-full min-h-[350px] max-h-[350px] bg-primary-blue rounded-2xl p-10 `}>
                        <span className='text-gray-400 uppercase'>
                            {item.title}
                        </span>
                        <div 
                            className='content text-[clamp(2rem,2vw,3rem)]' 
                            dangerouslySetInnerHTML={{__html: item.content}}></div>
                        <Link href={item.button.url}>
                            <a className={`btn btn-rounded self-center gap-2 btn-ghost-${index === 0 ? 'red' : 'green'}`}>
                                <span className='italic'>{item.button.text}</span>
                                <AiOutlineArrowRight/>
                            </a>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default InfoGallery