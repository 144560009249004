import { useState } from 'react'
import Image from 'next/image'
import { IComponentProps, MilestoneData, MilestoneListData } from '../../utils/types'
import ThemeMap from '../../styles/themes'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from "swiper"
import { useSwiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import "swiper/css/pagination"

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const MilestoneList = ({ data }: IComponentProps<MilestoneListData>) => {
  const [currentMilestone, setCurrentMilestone] = useState<MilestoneData>(data.milestoneList[0])
  const changeMilestone = (milestone: MilestoneData) => {
    setCurrentMilestone(milestone)
  }
  const currentTheme = ThemeMap[data.theme || "white"]
  return (
    <div id={(data.sectionId && data.sectionId !== "") ? data.sectionId : undefined} className={`milestone flex flex-col gap-10 items-center ${currentTheme.primary} ${currentTheme.textPrimary}`}>
      <div className='w-full flex flex-col gap-5 p-10 md:py-12 md:pt-24 md:px-44'>
        {data.categoryTitle && data.categoryTitle !== "" ? 
          <h3 className='uppercase text-primary-green font-bold text-xl'>{data.categoryTitle}</h3>
          :
          null
        }
        <h4 className='text-[2rem] md:text-[2.7rem] font-bold text-left max-w-4xl' style={{ lineHeight: '1'}}>
          {data.title}
        </h4>
      </div>
      <div className="relative w-full">
        <Swiper
          navigation={{
            prevEl: '.prev-btn',
            nextEl: '.next-btn'
          }}
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className}">${data.milestoneList[index].year}</span>`
            }
          }}
          breakpoints={{
            0: {
              pagination: {
                dynamicBullets: true,
                dynamicMainBullets: 1
              }
            },
            768: {
              pagination: {
                dynamicBullets: false
              }
            }
          }}
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          spaceBetween={10}
          className="mySwiper relative"
          loop={true}
        >
          {data.milestoneList.map((item, index) => (
            <SwiperSlide key={index}>
              <MilestoneItem
                changeFunction={changeMilestone}
                isCurrentMilestone={currentMilestone.year === item.year}
                data={item}
              />
            </SwiperSlide>
          ))}
          <button className="prev-btn cursor-pointer z-50 hidden md:block absolute left-[57%] top-[55%]">
            <IoIosArrowBack color={'#49C5B1'} size={50} />
          </button>
          <button className="next-btn cursor-pointer z-50 hidden md:block absolute right-[2%] top-[55%]">
            <IoIosArrowForward color={'#49C5B1'} size={50} />
          </button>
        </Swiper>
      </div>
    </div>
  )
}

export default MilestoneList

interface IMilestoneProps {
  data: MilestoneData,
  isCurrentMilestone: boolean,
  changeFunction: (milestone: MilestoneData) => void
}

const MilestoneItem = ({ data, isCurrentMilestone, changeFunction }: IMilestoneProps) => {
  const swiper = useSwiper();
  return (
    <div className='flex flex-col md:flex-row w-full' style={{ minHeight: '22rem' }}>
      <div className='relative w-full h-[15rem] md:h-auto md:w-[55%]'>
        <Image 
          src={`${ImageEndpoint}${data.image.data.attributes.url}`} 
          alt={data.image.data.attributes.alternativeText}
          layout='fill'
          objectFit='cover'
        />
      </div>
      <div className='flex flex-col gap-5 relative min-h-[16rem] w-full md:w-[45%] bg-white py-5 md:py-10 px-12 md:px-28 text-primary-blue'>
        {data.year && <h4 className='text-[1.8rem] md:text-[2.5rem] font-bold'>{data.year}</h4>}
        {data.content && <p className='text-[1rem] md:text-[1.4rem] font-bold'>{data.content}</p>}
        <button onClick={() => swiper.slidePrev()} className='block absolute top-[40%] left-[0%] md:hidden'>
          <IoIosArrowBack color={'#49C5B1'} size={50} />
        </button>
        <button onClick={() => swiper.slideNext()} className='block absolute top-[40%] right-[0%] md:hidden'>
          <IoIosArrowForward color={'#49C5B1'} size={50} />
        </button>
      </div>
    </div>
  )
}
