import { IComponentProps, TripleModuleData } from "../../utils/types"


const TripleModule = ({ data }: IComponentProps<TripleModuleData>) => {
  return (
    <div className="triple-module py-6 px-10 md:py-10 md:px-20 flex flex-col md:flex-row justify-around gap-10 bg-[#F2F2F5]">
      {data.modules.map((item, index) => (
        <div key={index} className="flex flex-col gap-3 md:w-1/3">
          {item.title && <h3>{item.title}</h3>}
          <div dangerouslySetInnerHTML={{__html: item.content}}></div>
        </div>
      ))}
    </div>
  )
}

export default TripleModule