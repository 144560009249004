type IProps = {
  children: React.ReactNode
}

const CardContainer = ({ children }: IProps) => {
  return (
    <div className={`card-container p-12 md:px-20`}>
      {children}
    </div>
  )
}

export default CardContainer
