import Link from 'next/link'
import ThemeMap from '../../styles/themes'
import ReactMarkdown from 'react-markdown'
import { IComponentProps, EngagementListData } from '../../utils/types'
import { AiOutlineArrowRight } from 'react-icons/ai'

const EngageUs = ({ data }: IComponentProps<EngagementListData>) => {
  const currentTheme = ThemeMap[data.theme]

  return (
    <div className={`engage-us engage-us-${data.theme} w-full ${currentTheme.primary} ${currentTheme.textPrimary} font-medium flex justify-center items-center flex-wrap gap-10 md:gap-28 px-10 py-20`}>
      <h3>
        <span className="text-[2.7rem] font-bold">{data.title}</span>
        <br/>
        <span className='text-[1.8rem]'>{data.subtitle}</span>
      </h3>
      <ul className="flex flex-col gap-7">
        {data.actionsList.map((item, index) => (
          <li className='flex items-center gap-2' key={index}>
            <Link href={item.url}>
              <a className='hover:underline'>
                <div dangerouslySetInnerHTML={{__html: item.content}}>
                </div>
              </a>
            </Link>
            <AiOutlineArrowRight color='white' size={25} />
          </li>
        ))}
      </ul>
    </div>
  )
} 

export default EngageUs