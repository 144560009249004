import Image from "next/image"
import Link from "next/link"
import useWindowDimensions from "../../utils/hooks"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper"
import { OrganisationData, ArrayItemObject, FeaturedVenturesData, IComponentProps, StrapiOrganisation } from "../../utils/types"
import VentureCard from "../cards/VentureCard"

import 'swiper/css'
import 'swiper/css/navigation'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL


const VentureFeatures = ({ data }: IComponentProps<FeaturedVenturesData>) => {
  const { width } = useWindowDimensions()
  return (
    <div className="venture-features p-10 md:px-20 md:py-16 flex flex-col gap-20 items-center justify-center text-primary-blue">
      <h3 className="text-5xl font-bold">Our Ventures</h3>
      {(width && width >= 1400) ? 
        <div className="ventures flex justify-around flex-wrap w-full">
          {data.organisations.data.map((item, index) => (
            <VentureCard noShift={true} data={item.attributes as OrganisationData} key={index} />
          ))}
        </div>
        :
        <div className="w-full">
          <Swiper
            navigation={{
              prevEl: '.prev-button',
              nextEl: '.next-button'
            }}
            modules={[Navigation]}
            breakpoints={{
              "0": {
                slidesPerView: 1
              },
              "350": {
                slidesPerView: 1
              },
              "600": {
                slidesPerView: 2
              },
              "950": {
                slidesPerView: 3
              },
              "1200": {
                slidesPerView: 4
              }
            }}
            slidesPerView={2}
            spaceBetween={10}
            className="mySwiper relative"
          >
            {data.organisations.data.map((item, index) => (
              <SwiperSlide key={index}>
                <VentureCard noShift={true} data={item.attributes as OrganisationData} />
              </SwiperSlide>
            ))}
            <button className="prev-button cursor-pointer z-50 absolute left-[0%] top-[53%]">
              <IoIosArrowBack color={'gray'} size={40} />
            </button>
            <button className="next-button cursor-pointer z-50 absolute right-[0%] top-[53%]">
              <IoIosArrowForward color={'gray'} size={40} />
            </button>
          </Swiper>
        </div>
      }
      <Link href={data.ctaUrl}>
        <a className="btn-ghost-blue btn-rounded px-4 py-2 text-[1.2rem] uppercase font-medium ">
          All Ventures
        </a>
      </Link>
    </div>
  )
}

export default VentureFeatures

interface IVentureLogo {
  item: ArrayItemObject<OrganisationData>
}

const VentureIcon = ({ item }: IVentureLogo) => {
  return (
    <Link href={`/ventures/${item.attributes.slug}`} passHref>
      <div className="venture flex flex-col gap-3 items-center">
        <div className="venture-image relative aspect-[1/1] rounded-full w-32 p-0 cursor-pointer">
          <Image src={`${ImageEndpoint}${item.attributes.isotype.data.attributes.url}`} alt={item.attributes.title} layout="fill" objectFit="cover" />
        </div>
        <div className="flex flex-col items-center">
          <span className={`uppercase text-[1.5rem] font-medium`}>
            {item.attributes.title}
          </span>
          <span className={`uppercase text-sm font-medium text-gray-500`}>
            {item.attributes.industrySection}
          </span>
        </div>
      </div>
    </Link>
  )
}