
import React, { useState } from 'react'
import Image from 'next/image'
import { GreenhouseJob, GreenhouseOffice, IComponentProps, JobBoardData } from '../../utils/types'
import Link from 'next/link'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

interface IStringMap {
    [key: string]: string
  }

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const borderColorMap: IStringMap = {
    'autolab': '#06AE6E',
    'polymath': '#F9423A',
    'aflore': '#50C700',
    'tani': '#49C5B1',
    'ropstar': '#000000',
    'elenas': '#4D4497',
    'polymath hunters': '#30BAA8',
    'nunnas': '#a89fff'
}

const ventureImageMap: IStringMap = {
    "nunnas": "/uploads/thumbnail_Nunnas_Logo_9a532b8e38.png",
    'autolab': '/uploads/Autolab_Isologotipo_01_d883e2e9a0.png?updated_at=2022-01-14T16:14:29.727Z',
    'polymath': '/uploads/V1_Recurso_10_cd9c0bf308.png?updated_at=2022-02-08T20:25:43.549Z',
    'aflore': '/uploads/Aflore_Isologotipo_01_0b5946d74c.png?updated_at=2022-01-14T16:16:08.820Z',
    'tani': '/uploads/Tani_Isologotipo_01_423f2cf36d.png?updated_at=2022-01-18T13:40:36.332Z',
    'ropstar': '/uploads/Propuestas_identificador_enviar_03_e721c9fa59.png?updated_at=2022-01-18T13:51:51.238Z',
    'elenas': '/uploads/Elenas_Isologotipo_01_a8cd9fccee.png?updated_at=2022-01-14T18:08:14.035Z',
    'polymath hunters': '/uploads/Polymath_hunters_isotipo_Mesa_de_trabajo_1_a77c8e3b5f.png?updated_at=2022-11-09T20:45:05.809Z'
}

const JobBoard = ({ data }: IComponentProps<JobBoardData>) => {
    const [ventureFilter, setVentureFilter] = useState<string | null>(null)
    const [expertiseFilter, setExpertiseFilter] = useState<string | null>(null)
    const [countryFilter, setCountryFilter] = useState<string | null>(null)

    const [showVenturesDropdown, setShowVenturesDropdown] = useState<boolean>(false)
    const [showExpertiseDropdown, setShowExpertiseDropdown] = useState<boolean>(false)
    const [showCountryDropdown, setShowCountryDropdown] = useState<boolean>(false)

    const elenasItem: GreenhouseJob= {
        id: 1,
        internal_job_id: 1,
        absolute_url: 'https://jobs.lever.co/elenas',
        location: {
            name: 'Visit their website'
        },
        title: 'See all open positions',
        content: '',
        departments: [],
        offices: [{
            name: 'Elenas'
        } as GreenhouseOffice]
    } 

    const ventures = data.jobs.filter(item => item.offices.length).map(item => item.offices[0].name)
    const uniqueVentures = ventures.filter((value, index, array) => array.indexOf(value) === index).concat('Elenas')

    const departments = data.jobs.filter(item => item.departments.length).map(item => item.departments[0].name)
    const uniqueDepartments = departments.filter((value, index, array) => array.indexOf(value) === index)

    let countriesList: string[] = []
    data.jobs
        .filter(item => item.metadata && item.metadata.length && item.metadata.some((item: any) => item.name === 'Location' && item.value !== null))
        .map(item => item.metadata.find((item: any) => item.name === 'Location').value.map((item: string) => item && (countriesList = [...countriesList, item])))
    const uniqueCountries = countriesList.filter((value, index, array) => array.indexOf(value) === index)

    const sortJobs = (maJobs: GreenhouseJob[]) => {
        let sortedJobs = maJobs
        .filter(item => item.offices.length)
        .filter(item => item.offices[0].name === 'Polymath')
        .concat(maJobs.filter(item => item.offices.length).filter(item => item.offices[0].name !== 'Polymath')
            .sort((a, b) => a.offices[0].name > b.offices[0].name ? 1 : -1))

        sortedJobs = (ventureFilter === null || ventureFilter === 'Elenas') ? sortedJobs.concat(elenasItem) : sortedJobs

        return sortedJobs
    }

    const filterJobs = () => {
        let filtered = data.jobs
        ventureFilter ? 
            (filtered = filtered.filter(item => item.offices.length && item.offices[0].name === ventureFilter)) 
            : 
            filtered
        expertiseFilter ? 
            (filtered = filtered.filter(item => item.departments.length && item.departments[0].name === expertiseFilter)) 
            : 
            filtered
        countryFilter ? 
            (filtered = filtered.filter(item => item.metadata?.length && item.metadata.some((item: any) => item.name === 'Location' && item.value?.some((item: string) => item && item === countryFilter))))
            :
            filtered
        return filtered
    }

    return (
        <div id={'job_board'} className="gh-jobs w-full py-10 px-3 md:px-20 lg:px-48 items-center flex flex-col gap-8">
            <div className='flex flex-col gap-5 w-full'>
                <div className="flex flex-col md:flex-row gap-5 w-full">
                    <DropdownFilter
                        criteria={'Expertise'}
                        values={uniqueDepartments}
                        filter={expertiseFilter}
                        filterSet={setExpertiseFilter}
                        showStatus={showExpertiseDropdown}
                        showFunction={setShowExpertiseDropdown}
                    />
                    <DropdownFilter
                        criteria={'Country'}
                        values={uniqueCountries}
                        filter={countryFilter}
                        filterSet={setCountryFilter}
                        showStatus={showCountryDropdown}
                        showFunction={setShowCountryDropdown}
                    />
                </div>
                <PillsFilter
                    criteria={'Ventures'}
                    values={uniqueVentures}
                    filter={ventureFilter}
                    filterSet={setVentureFilter}
                    showStatus={showVenturesDropdown}
                    showFunction={setShowVenturesDropdown}
                />
            </div>
            {sortJobs(filterJobs()).length > 0 ? 
                <ul className="job-list flex flex-col w-full">
                    {sortJobs(filterJobs()).map((item, index) => (
                        <li key={index}>
                            <JobItem job={item} />
                        </li>
                    ))}
                </ul>
                :
                <div className='w-full flex flex-col gap-1 justify-center items-center py-10 md:py-20 px-10 md:px-40 text-center text-gray-500 text-[1.2rem]'>
                    <p className='text-[1.4rem] font-medium'>There are no open positions in <span className='font-bold'>{expertiseFilter}</span> at <span className='font-bold'>{ventureFilter}</span></p>
                </div>
            }
        </div>
    )
}

export default JobBoard

interface IItemProps {
    job: GreenhouseJob
}

const JobItem = ({ job }: IItemProps) => {
    return (
        <Link href={job.absolute_url}>
            <a
                className={`job${job.offices[0].name === 'Elenas' && '-elenas'} py-3 flex gap-5 flex-wrap cursor-pointer hover:scale-[1.02] transition-all`}
                style={{ borderBottom: 'solid 1px gray' }}
                target={job.offices[0].name === 'Elenas' ? '_blank' : undefined}
            >
                <div 
                    className='flex items-center gap-8 md:gap-5 px-6 w-full text-gray-500 py-3' 
                    style={{ borderLeft: `solid 4px ${borderColorMap[job.offices[0].name.toLowerCase()]}` }}
                >
                    {job.offices?.length && <div className="relative w-16 h-16 overflow-hidden">
                            <Image
                                src={`${ImageEndpoint}${ventureImageMap[job.offices[0].name.toLowerCase()]}`}
                                alt={'imagen'}
                                layout='fill'
                                objectFit='cover'
                            />
                    </div>}
                    <div className='flex flex-col md:gap-5 md:flex-row w-2/3 md:w-full'>
                        <div className="job-data flex flex-col justify-center md:w-1/3">
                            <h3 className='text-lg text-gray-600 font-bold'>
                                {job.title}
                            </h3>
                            <span>
                                {job.offices.map(item => item.name)}
                            </span>
                        </div>
                        <div className='flex items-center md:w-1/3'>
                            <span>{job.departments.map(item => item.name)}</span>
                        </div>
                        <div className='location flex items-center md:w-1/4 gap-2'>
                            <span>
                                {job.metadata?.some((item: any) => item.name === 'Location' && item.value !== null) ? 
                                    job.metadata.find((item: any) => item.name === 'Location').value.map((item: string, index: number) => item + (index === (job.metadata.find((item: any) => item.name === 'Location').value.length - 1) ? '' : ', ')) 
                                    : 
                                    job.location.name
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    )
}

interface IFilterProps {
    criteria: string,
    values: Array<string>,
    filter: string | null,
    filterSet: React.Dispatch<React.SetStateAction<string | null>>,
    showStatus: boolean,
    showFunction: React.Dispatch<React.SetStateAction<boolean>>
}

const DropdownFilter = ({ criteria, values, filter, filterSet, showStatus, showFunction }: IFilterProps) => {
    return (
        <div className="flex flex-col justify-start items-center relative w-full md:w-1/3 lg:w-[30%]">
            <span className='self-start text-lg md:text-xl text-gray-600 mb-3'>{criteria}</span>
            <span 
                className={`flex flex-row justify-between items-center btn-rounded btn-filled-blue uppercase py-2 px-4 text-[0.7rem] md:text-[0.8rem] w-full cursor-pointer`}
                onClick={() => showFunction(!showStatus)}
            >{filter ? filter : 'All'} {showStatus ? <IoIosArrowUp color='white' size={20} /> : <IoIosArrowDown color='white' size={20} />}</span>
            <div className={`dropdown w-full flex flex-col gap-1 md:gap-1 z-[999] absolute top-[5.1rem] ${showStatus ? 'block' : 'hidden'}`}>
                <button 
                    className={`btn-rounded uppercase ${filter === null ? 'btn-filled-blue' : 'btn-filled-gray'} py-2 px-4 text-left text-[0.7rem] md:text-[0.7rem]`}
                    onClick={() => {
                        filterSet(null)
                        showFunction(false)
                    }}
                >
                    All
                </button>
                {values.map((item, index) => (
                    <button
                        key={index}
                        className={`btn-rounded uppercase ${item === filter ? 'btn-filled-blue' : 'btn-filled-gray'} py-2 px-4 text-left text-[0.7rem] md:text-[0.7rem]`}
                        onClick={() => {
                            filterSet(item)
                            showFunction(false)
                        }}
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    )
}

const PillsFilter = ({ criteria, values, filter, filterSet, showStatus, showFunction }: IFilterProps) => {
    return (
        <div className='flex flex-col gap-3 w-full md:w-1/2 lg:w-2/3'>
            <span className='text-lg md:text-xl text-gray-600'>{criteria}</span>
            <div className='flex flex-wrap gap-3'>
                <button 
                    className={`${null === filter ? 'btn-filled-blue' : 'btn-filled-gray'} btn-rounded py-2 px-3`}
                    onClick={() => {
                        filterSet(null)
                    }}
                >
                    All
                </button>
                {values.map((item, index) => (
                    <button 
                        key={index} 
                        className={`${item === filter ? 'btn-filled-blue' : 'btn-filled-gray'} btn-rounded py-2 px-3`}
                        onClick={() => {
                            filterSet(item)
                        }}
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    )
}