
interface NewsletterProps {
    actionUrl?: string
}

export default function NewsLetter({ actionUrl = 'https://polymathv.us3.list-manage.com/subscribe/post?u=bf424acd364ec846df798dafc&amp;id=5dd0bb3276&amp;v_id=1&amp;f_id=00cad1e1f0' }: NewsletterProps) {

    return (
        <div id="mc_embed_signup">
            <form action={actionUrl} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate flex flex-col gap-5" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group mb-4">
                        <input placeholder="Enter your e-mail address" type="email" defaultValue="" name="EMAIL" className="w-full required xs:text-center md:text-left email contact-input" id="mce-EMAIL" />
                    </div>
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                        <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                    </div>   
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                        <input type="text" name="b_bf424acd364ec846df798dafc_a226173f0d" tabIndex={-1} defaultValue="" />
                    </div>
                    <div className="clear flex justify-center md:justify-start">
                        <input type="submit" lang="en" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="elect-none cursor-pointer btn-lg py-1 px-4 btn-rounded btn-filled btn-filled-blue btn-filled-blue-outline" />
                    </div>
                </div>
            </form>
        </div>

    )

}