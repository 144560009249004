import Image from "next/image";
import ThemeMap from "../../styles/themes";
import { IComponentProps, ImageDataBlockData } from "../../utils/types";

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL;

const ImageDataBlock = ({ data }: IComponentProps<ImageDataBlockData>) => {
  const currentTheme = ThemeMap[data.theme ? data.theme : "white"];
  const getLayoutStyling = () => {
    return data.containImage
      ? data.imageAlignment === "right"
        ? "md:pr-0 md:pl-10 lg:pl-20"
        : "md:pl-0 md:pr-10 lg:pr-20"
      : "md:px-10 lg:px-20";
  };
  return (
    <div
      className={`venture-block venture-block-${data.theme} flex flex-col ${
        data.containImage ? "py-10 md:py-12 lg:py-16" : ""
      } ${currentTheme.primary} ${currentTheme.textPrimary} ${
        data.imageAlignment === "right" ? "md:flex-row-reverse" : "md:flex-row"
      }`}
    >
      <div
        className={`w-full md:w-1/2 aspect-[1.9/1] md:aspect-[1.7/1] flex justify-center items-center ${
          data.containImage ? `pt-10 px-10 md:px-20 md:pt-0` : ""
        }`}
      >
        <div
          className={`venture-block-image relative ${
            data.containImage
              ? `w-full ${
                  data.media ? "aspect-[16/9]" : "aspect-[1.5] md:aspect-[1.8]"
                } rounded-xl overflow-hidden shadow-2xl`
              : "w-full h-full"
          }`}
        >
          {data.media ? (
            <iframe
              src={data.media.URL}
              style={{ width: "100%", aspectRatio: "16/9" }}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <Image
              src={`${ImageEndpoint}${data.image.data?.attributes.url}`}
              alt="Venture"
              layout="fill"
              objectFit="cover"
            />
          )}
        </div>
      </div>
      <div
        className={`venture-block-info flex flex-col gap-5 justify-center w-full md:w-1/2 px-10 ${getLayoutStyling()} py-10 font-medium`}
      >
        {data.title && (
          <h3 className="text-base text-xl  md:text-[1.4rem] font-bold primary-blue-100 uppercase">
            {data.title}
          </h3>
        )}
        <div
          className={`content text-lg ${currentTheme.textPrimary}`}
          dangerouslySetInnerHTML={{ __html: data.content! }}
        ></div>
      </div>
    </div>
  );
};

export default ImageDataBlock;
