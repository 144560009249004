import qs from 'qs'

export function getStrapiMedia(url: string) {
  if (url == null) {
    return null
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith("http") || url.startsWith("//")) {
    return url
  }
  // Otherwise prepend the URL path with the Strapi URL
  const strapiUrl = `${process.env.NEXT_PUBLIC_UPLOADS_URL}${url}`

  return strapiUrl
}

function getStrapiApiToken(): string {
  const token = process.env["STRAPI_API_TOKEN"];
  if (!token) {
    throw new Error(
      `Couldn't find environment variable: STRAPI_API_TOKEN`
    );
  } else {
    return token;
  }
}

export function getStrapiURL(path: string, query: any) {
  let url = new URL(`${path}?${qs.stringify(query)}`, process.env.STRAPI_API_URL || 'http://localhost:1337');
  return url.href
}

export async function fetchAPI<T>(path: string, query?: any, token?: string): Promise<T> {
  const apiToken = token || getStrapiApiToken()
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiToken}`
    },
  }

  const options = {
    ...defaultOptions,
  }

  const requestUrl = getStrapiURL(path, query)
  return await fetch(requestUrl, options)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(data => data as T);
}



