import { useState } from "react"
import CardContainer from "../containers/CardContainer"
import TeamCard from '../cards/TeamCard'
import { IComponentProps, StrapiTeamMember, TeamMemberListData } from '../../utils/types'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

const TeamList = ({ data }: IComponentProps<TeamMemberListData>) => {
  const [typeFilter, setTypeFilter] = useState<string | null>("TeamMember")
  const [departmentFilter, setDepartmentFilter] = useState<string | null>(null)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  interface StringMap {
    [key: string]: string
  }

  const memberTypes: StringMap = {
    "TeamMember": "Team Members",
    "Advisor": "Advisors",
    "Alumni": "Alumni"
  }

  const teamObjects = data.team_members.data.map(item => item.attributes)

  const teamWithDeps = data.team_members.data.map(item => item.attributes).filter(item => item.department.data)

  const types = teamObjects.filter(item => item.memberType).map(item => item.memberType)

  const uniqueTypes = types.filter((value, index, array) => array.indexOf(value) === index)

  const departments = teamWithDeps.map(item => item.department.data.attributes.title)

  const uniqueDeps = departments.filter((value, index, array) => array.indexOf(value) === index)

  function numerically(ascending: boolean) {
    return function (a: StrapiTeamMember, b: StrapiTeamMember) {
  
      // equal items sort equally
      if (a.displayOrder === b.displayOrder) {
          return 0
      }
      // nulls sort after anything else
      else if (a.displayOrder === null) {
          return 1
      }
      else if (b.displayOrder === null) {
          return -1
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
          return a.displayOrder < b.displayOrder ? -1 : 1;
      }
      // if descending, highest sorts first
      else { 
          return a.displayOrder < b.displayOrder ? 1 : -1;
      }
  
    }
  }

  const filterTeam = () => {
    let filtered = data.team_members.data.map(item => item.attributes)
    switch (departmentFilter) {
      case null:
        return filtered
      default:
        return filtered.filter(item => item.department.data && (item.department.data.attributes.title === departmentFilter))
    }
  }

  const filterByType = () => {
    return filterTeam().filter(item => item.memberType && (item.memberType === typeFilter))
  }

  return (
    <div className="team-container">
      <div className="flex flex-col-reverse px-10 lg:px-20 md:flex-row gap-0 md:gap-10 mt-5">
        {uniqueTypes.length && uniqueTypes[0] !== undefined ?
          <div className="team-filter py-3 text-primary-blue flex gap-5">
            <span className="text-lg flex items-center font-medium">Type</span>
            {uniqueTypes.sort((a, b) => a === 'TeamMember' ? -1 : 1).map((item, index) => (
              <button 
                key={index} 
                className={`${item === typeFilter ? 'btn-filled-blue' : 'btn-ghost-blue'} btn-rounded px-4 text-xs md:text-base`}
                onClick={() => setTypeFilter(item)}
              >
                {memberTypes[item]}
              </button>
            ))}
          </div>
          :
          null
        }
        {uniqueDeps.length ?
          <div className="team-filter py-3 text-primary-blue flex gap-8 w-full md:w-1/2">
            <span className="text-lg flex items-center font-medium">Departments</span>
            <div className="team-select flex justify-center items-center relative w-full lg:w-1/2">
              <span
                className={`flex flex-row justify-between items-center btn-rounded btn-filled-blue py-1 md:py-2 px-4 text-[0.7rem] md:text-[1rem] w-full`}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                {departmentFilter ? departmentFilter : 'View All'} {showDropdown ? <IoIosArrowUp color='white' size={20} /> : <IoIosArrowDown color='white' size={20} />}
              </span>
              <div className={`dropdown w-full flex flex-col gap-1 md:gap-2 z-[999] absolute top-12 ${showDropdown ? 'block' : 'hidden'}`}>
                <button
                  className={`btn-rounded ${departmentFilter === null ? 'btn-filled-blue' : 'btn-filled-white'} py-2 px-4 text-left text-[0.7rem] md:text-[1rem]`}
                  onClick={() => {
                    setDepartmentFilter(null)
                    setShowDropdown(false)
                  }}
                >
                  View all
                </button>
                {uniqueDeps.map((item, index) => (
                  <button
                    key={index}
                    className={`btn-rounded ${item === departmentFilter ? 'btn-filled-blue' : 'btn-filled-gray'} py-2 px-4 text-left text-[0.7rem] md:text-[1rem]`}
                    onClick={() => {
                      setDepartmentFilter(item)
                      setShowDropdown(false)
                    }}
                  >
                    {item}
                  </button>
                ))}
              </div>
            </div>
          </div>
          :
          null
        }
      </div>
      <CardContainer>
        {filterByType().sort(numerically(true)).map((item, index) => (
          <TeamCard data={item} key={index} />
        ))}
      </CardContainer>
    </div>
  )
}

export default TeamList
