import React from 'react'
import { IComponentProps, ScaledStepsData } from '../utils/types'
import Image from 'next/image'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const ScaledSteps = ({ data }: IComponentProps<ScaledStepsData>) => {
  return (
    <div className={`relative w-full px-[1.125rem] pr-[3rem] md:px-[5rem] lg:px-[8rem] py-[2rem] pb-[5rem] md:py-[5rem] lg:py-[3.5rem] lg:pb-[7rem] flex flex-col gap-8 md:gap-12 relative`}>
      <Image src={ImageEndpoint + data.bgImageDesktop.data.attributes.url} alt={data.bgImageDesktop.data.attributes.alternativeText} className='invisible md:visible' layout='fill' objectFit='cover' />
      <Image src={ImageEndpoint + data.bgImageMobile.data.attributes.url} alt={data.bgImageMobile.data.attributes.alternativeText} className='visible md:invisible' layout='fill' objectFit='cover' />
      <h3 className="pl-[4rem] md:pl-0  z-[99] text-primary-green font-bold text-lg md:text-4xl">{data.title}</h3>
      <ol className="z-[99] flex flex-col gap-[2rem] md:gap-[3rem] md:max-w-[85%]">
        {data.steps.map((item, index) => (
          <li key={index} className="flex flex-col gap-5">
            <h4 className="flex gap-5 items-center text-lg md:text-2xl font-bold text-primary-green">
              <span className='min-w-[2rem] w-[2rem] h-[2rem] md:w-[2rem] md:h-[2rem] bg-primary-green md:text-xl flex justify-center items-center text-white font-semibold rounded-full'>{index + 1}</span> {item.title}
            </h4>
            <p className="pl-[3.4rem] md:pl-[3.4rem] text-md md:text-lg font-bold">{item.content}</p>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default ScaledSteps