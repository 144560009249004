import { useState } from "react"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { AccordionData, IComponentProps } from "../../utils/types"


const Accordion = ({ data }: IComponentProps<AccordionData>) => {
  return (
    <div className="py-12 md:px-20 lg:px-32 xl:px-40 md:py-12 lg:py-20 flex flex-col gap-5 w-full accordion">
      <h3 className="px-8 pt-3 md:pt-0 md:px-[1.9rem] text-[2rem] md:text-[2.7rem] font-bold text-primary-green">
        {data.title}
      </h3>
      <div className="w-full flex flex-col">
        {data.items.map((item, index) => (
          <AccordionItem key={index} data={item} isLast={index === (data.items.length - 1)} />
        ))}
      </div>
    </div>
  )
}

export default Accordion

interface IProps {
  data: {
    title: string,
    content: string
  },
  isLast: boolean
}

const AccordionItem = ({ data, isLast }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <div 
      className="flex flex-col accordion-item" 
      style={{ borderBottom: !isLast ? 'solid 1px #adb3bc' : 'none'}}
    >
      <h4
        onClick={() => setIsOpen(!isOpen)} 
        className="w-full text-primary-blue transition-all duration-1000 hover:cursor-pointer hover:bg-gray-200 py-5 px-8 text-xl font-bold flex items-center justify-between"
      >
        {data.title} <IoIosArrowDown color='black' size={20} className={`transition-all ${isOpen && 'rotate-180'}`} />
      </h4>
      <div 
        dangerouslySetInnerHTML={{__html: data.content}}
        className={`accordion-item-content w-full transition-all overflow-hidden ${isOpen ? 'max-h-96 py-5 px-8 shadow-inner' : 'max-h-0 p-0'}`} 
      />
    </div>
  )
}