import { useState } from "react"
import Image from "next/image"
import Link from "next/link"
import useWindowDimensions from "../../utils/hooks"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper"
import { IComponentProps, ProcessData, StepData, ArrayItemObject } from "../../utils/types"
const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

import 'swiper/css'
import 'swiper/css/navigation'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

const Process = ({ data }: IComponentProps<ProcessData>) => {
  const [currentStep, setCurrentStep] = useState<ArrayItemObject<StepData>>(data.steps.data[0])
  const { width } = useWindowDimensions()

  const updateStep = (step: ArrayItemObject<StepData>) => {
    setCurrentStep(step)
  }

  return (
    <div id={(data.sectionId && data.sectionId !== "") ? data.sectionId : undefined} className={`data-block data-block-blue process flex flex-col gap-10 md:gap-20 items-center justify-center p-10 bg-light-gray text-primary-blue md:py-24 md:px-44 lg:py-28`}>
      <div className={`w-full flex ${!data.isActive ? 'justify-center' : 'justify-start md:pl-5'}`}>
        <div className={`text-4xl font-bold ${!data.isActive ? 'text-center' : 'text-left'} max-w-5xl`} dangerouslySetInnerHTML={{ __html: data.title }}>
        </div>
      </div>
      {(width && width >= 1024) ?
        <div className="ventures flex justify-around flex-wrap w-full">
          {data.steps.data.map((item, index) => (
            <StepIcon updateFunction={updateStep} isCurrentStep={currentStep.attributes.name === item.attributes.name} isActive={data.isActive} item={item} key={index} />
          ))}
        </div>
        :
        <div className="w-full">
          <Swiper
            navigation={{
              prevEl: '.prev-button',
              nextEl: '.next-button'
            }}
            modules={[Navigation]}
            breakpoints={{
              "0": {
                slidesPerView: 1
              },
              "600": {
                slidesPerView: 2
              },
              "950": {
                slidesPerView: 3
              }
            }}
            slidesPerView={2}
            spaceBetween={10}
            className="mySwiper relative"
          >
            {data.steps.data.map((item, index) => (
              <SwiperSlide key={index}>
                <StepIcon updateFunction={updateStep} isCurrentStep={currentStep.attributes.name === item.attributes.name} isActive={data.isActive} item={item} />
              </SwiperSlide>
            ))}
            <button className="prev-button cursor-pointer z-50 absolute left-[0%] top-[53%]">
              <IoIosArrowBack color={'gray'} size={40} />
            </button>
            <button className="next-button cursor-pointer z-50 absolute right-[0%] top-[53%]">
              <IoIosArrowForward color={'gray'} size={40} />
            </button>
          </Swiper>
        </div>
      }
      {data.isActive ?
        (currentStep &&
          <div className="description p-10 w-full md:w-3/5 lg:w-2/3 text-lg text-primary-blue">
            {currentStep.attributes.RichTextDescription ?
              <div 
                className="text-lg md:text-xl lg:text-[1.4rem]"
                dangerouslySetInnerHTML={{
                  __html: currentStep.attributes.RichTextDescription
                }} 
              />
              :
              <p>{currentStep.attributes.description}</p>
            }
          </div>
        )
        :
        null
      }
      {data.callToAction ?
        <Link href={data.callToAction.url}>
          <a  {...data.callToAction.newTab ? {target: '_blank', rel: 'noreferrer'} : {}} className={`uppercase px-4 py-2 text-lg select-none btn-rounded btn-${data.callToAction.theme}-${data.callToAction.color} text-[1.2rem]`}>Learn More</a>
        </Link>
        :
        null
      }
    </div>
  )
}

export default Process

interface IStepIcon {
  item: ArrayItemObject<StepData>,
  isActive: boolean,
  isCurrentStep: boolean,
  updateFunction: (step: ArrayItemObject<StepData>) => void
}

const StepIcon = ({ item, isActive, isCurrentStep, updateFunction }: IStepIcon) => {
  const firstWord = item.attributes.name.split(' ')[0]
  const restOfName = item.attributes.name.split(' ').splice(1, item.attributes.name.split(' ').length).join(' ')

  return (
    <div className={`step-icon flex flex-col items-center justify-start gap-5 text-primary-blue ${isActive && 'cursor-pointer'} ${isActive && (isCurrentStep ? 'opacity-100' : 'opacity-50 hover:opacity-100')}`} onClick={() => updateFunction(item)}>
      <div className={`icon relative w-32 aspect-[1/1] rounded-full overflow-hidden p-0 bg-primary-blue`}>
        <Image src={`${ImageEndpoint}${item.attributes.icon.data.attributes.url}`} alt={item.attributes.name} layout="fill" objectFit="cover" />
      </div>
      <h4 style={{lineHeight: '0.9'}} className={`uppercase text-center max-w-[200px]`}>
        <span className="text-2xl lg:text-3xl font-bold">{firstWord}</span>
        <br />
        <span style={{fontSize: '1.2rem'}}>{restOfName}</span>
      </h4>
    </div>
  )
}
