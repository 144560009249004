import ThemeMap from '../../styles/themes'
import { IComponentProps, DataBlockData } from '../../utils/types'
import Link from 'next/link'
import Image from 'next/image'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const DataBlock = ({ data }: IComponentProps<DataBlockData>) => {
    const currentTheme = ThemeMap[data.theme || "white"]

    const imageAlignment = data.alignImage || 'left'

    const getClasses = () => {
        return `data-block data-block-${data.theme} flex flex-col justify-center ${data.bgImage?.data !== null ? 'p-0 h-full' : (data.narrowHeight === true ? 'px-10 py-20 md:p-20 h-full': 'px-10 md:px-40 lg:px-60 py-20 md:py-28')} ${currentTheme.primary} ${currentTheme.textPrimary} overflow-hidden`
    }

    const isExternalLink = (url?: string) => {
        if (typeof window !== 'undefined' && url) {
            let e = document.createElement('a');
            e.href = url;
            return e.host !== window.location.host;
        } else {
            return false
        }
    }

    return (
        <div className={getClasses()} id={(data.sectionId && data.sectionId !== "") ? data.sectionId : undefined}>
            {data.bgImage?.data === null ? 
                <div className={`flex flex-col gap-8 items-${data.narrowHeight === true ? 'start' : 'center'} text-${data.alignment}`}>
                    {data.sectionTitle && data.sectionTitle !== "" ?
                        <span className='self-start uppercase text-gray-600 font-bold text-xl'>
                            {data.sectionTitle}
                        </span>
                        :
                        null
                    }
                    {(data.title && data.title !== "") &&
                        <div dangerouslySetInnerHTML={{__html: data.title}}>
                        </div>
                    }
                    {(data.content && data.content !== "") &&
                        <div dangerouslySetInnerHTML={{__html: data.content}}>
                        </div>
                    }
                    {data.callToAction &&
                        <Link href={data.callToAction.url}>
                            <a {...data.callToAction.newTab ? {target: '_blank', rel: 'noreferrer'} : {}} className={`self-${data.alignment} uppercase px-4 py-2 text-[1.2rem] select-none btn-rounded btn-${data.callToAction.theme}-${data.callToAction.color}`}>
                                {data.callToAction.text}
                            </a>
                        </Link>
                    }
                </div>
                :
                (data.narrowHeight !== true ? 
                    <div className='relative w-full aspect-[1.7] md:aspect-[2.3]'>
                        <Image
                            src={`${ImageEndpoint}${data.bgImage?.data?.attributes.url}`}
                            alt={data.bgImage?.data?.attributes.alternativeText}
                            layout='fill'
                            objectFit='cover'
                        />
                    </div>
                    :
                    <Image
                        src={`${ImageEndpoint}${data.bgImage?.data?.attributes.url}`}
                        alt={data.bgImage?.data?.attributes.alternativeText}
                        height={data.bgImage?.data.attributes.height}
                        width={data.bgImage?.data.attributes.width}
                    />
            )}
        </div>
    )
}

export default DataBlock
