import React from 'react'
import { IComponentProps, ImageGridData } from '../utils/types'
import Image from 'next/image'

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const ImageGrid = ({ data }: IComponentProps<ImageGridData>) => {
  return (
    <div className={`image-grid w-full px-10 md:px-20 lg:px-16 py-12 md:py-[4.8rem] lg:py-[5.5rem] flex flex-col gap-10 lg:gap-20 justify-center items-center bg-light-gray text-primary-blue`}>
      <div className='text-3xl md:text-4xl font-bold text-center' dangerouslySetInnerHTML={{__html: data.title}} style={{ lineHeight: '2.8rem' }}></div>
      <div className='grid gap-3 md:gap-4 grid-cols-1 md:grid-cols-3 w-full px-5 md:px-10 lg:px-26 xl:px-36'>
        {data.teams.map((item, index) => (
          <div key={index} className={`relative ${((data.teams.length % 2 !== 0) && (index === (data.teams.length - 1))) ? 'col-start-1 md:col-start-2' : ''} w-full aspect-[2.8] md:aspect-[1.9] flex justify-center items-center`}>
            <Image src={ImageEndpoint + item.image.data.attributes.url} alt={item.image.data.attributes.alternativeText} layout={'fill'} objectFit={'cover'} />
            <div className="gradient absolute top-0 bottom-0 left-0 right-0 flex justify-center items-end pb-4">
              <span className='text-[1.3rem] tracking-wider text-center'>{item.name}</span>
            </div>
          </div>))
        }
      </div>
    </div>
  )
}

export default ImageGrid