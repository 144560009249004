import React from 'react'
import { DataFormData, FormInputData, IComponentProps } from '../utils/types'

interface DataFormProps {
  data: DataFormData,
  takeFullSpace?: boolean
}

const DataForm = ({ data, takeFullSpace }: DataFormProps) => {
  return (
    <div id={data.sectionId ? data.sectionId : undefined} className='data-form flex items-center justify-center p-5 md:p-20'>
      <div className={`w-full ${takeFullSpace !== true && 'md:w-4/5 lg:w-2/3'} p-10 flex flex-col gap-5`}>
        <h3 className='text-2xl font-bold text-primary-blue'>{data.formTitle}</h3>
        <p className='text-lg font-medium'>{data.description}</p>
        <form action={data.actionLink} method="post" className='grid grid-cols-2 gap-3' target='_blank' noValidate>
          {data.formInputs.map((item, index) => (
            <FormInput key={index} input={item} />
          ))}
          <p className='col-span-2 text-base py-2 text-gray-600'>{data.formFooter}</p>
          <input 
            className={`px-4 py-2 col-span-2 btn-${data.submitButton.theme}-${data.submitButton.color}`}
            type="submit" 
            value={data.submitButton.text} 
          />
        </form>
      </div>
    </div>
  )
}

export default DataForm

interface FormInput {
  input: FormInputData
}

const FormInput = ({ input }: FormInput) => {
  return (
    <div className={`form-input flex ${input.inputType === 'boolean' ? 'flex-row justify-center items-center gap-5' : 'flex-col'} ${input.widthSpan === 'FullWidth' ? 'col-span-2' : 'col-span-2 md:col-span-1'}`}>
      <label htmlFor={'input_' + input.id}>{input.label}</label>
      <input 
        name={input.name}
        className={`px-3 py-2 border-solid border-[1px] border-gray-400 rounded-sm`}
        id={'input_' + input.id} 
        type={input.inputType === 'boolean' ? 'checkbox' : input.inputType} 
        placeholder={input.placeholder} 
      />
    </div>
  )
}