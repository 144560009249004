import Image from "next/image";
import Link from "next/link";
import { FeaturedLinkData, IComponentProps } from "../../utils/types";

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL

const FeaturedLink = ({ data }: IComponentProps<FeaturedLinkData>) => {
  return (
    <div className={`featured-link py-20 md:px-20 md:py-32`}>
      <div className="relative md:shadow-2xl">
        <Image
          src={`${ImageEndpoint}${data.image.data.attributes.url}`}
          alt={data.image.data.attributes.alternativeText}
          layout="fill"
          objectFit="cover"
          className="z-[10] meme"
        />
        <div className="mask p-10 w-full relative z-[10]">
          <div className={`flex flex-col gap-8 md:gap-5 md:gap-10`}>
            <h4 className="text-[2.6rem] md:text-[2.3rem] lg:text-[2.5rem] xl:text-[2.7rem] w-full md:w-2/3 lg:w-1/2 font-bold text-white">
              {data.title}
            </h4>
            <h5 className="text-[1.6rem] md:text-[1.4rem] w-full md:w-1/3 text-white">
              {data.subtitle}
            </h5>
            <Link href={data.url}>
              <a className="py-2 w-full md:w-fit px-16 btn-filled-green btn-rounded text-center text-lg" target={'_blank'} rel="noreferrer">
                {data.ctaLink || 'Learn More'}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedLink;
