import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { HiSearch } from 'react-icons/hi'
import { ArrayItemObject, ArticleType, IComponentProps, ResourceData, ResourceGridData } from '../utils/types'
import ArticleCard from './cards/ArticleCard'

const LatestArticles = ({ data }: IComponentProps<ResourceGridData>) => {
  const [searchText, setSearchText] = useState('')
  /* const getLatests = (limit: number = 3) => {
    return filterTypes(data.resources)
      .filter(x => x.creationDate)
      .map(item => item && { attributes: item })
      .sort(dateSort)
  } */

  const router = useRouter()

  function dateSort(a: ArrayItemObject<ResourceData>, b: ArrayItemObject<ResourceData>) {
    const dateA = a.attributes.creationDate// || a.publishedAt || a.createdAt
    const dateB = b.attributes.creationDate// || b.publishedAt || b.createdAt
    if(dateA == dateB){
      return 0
    }
    return dateA < dateB ? 1 : -1
  }

  const getLatests = () => {
    return data.resources
      .filter(x => x.creationDate)
      .map(item => item && { attributes: item })
      .sort(dateSort).map(item => item && item.attributes)
  }
  
  return (
    <div className='latest-articles flex flex-col m-auto w-full p-5 md:p-20 md:py-12 gap-5'>
      <div className='w-full flex flex-col-reverse md:flex-row justify-between'>
        <h3 className='text-[2.4rem] font-[700] text-primary-blue'>
          Latest publications
        </h3>
        <div className='self-end search flex w-full md:w-fit items-center justify-between rounded-full bg-primary-gray overflow-hidden'>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (searchText && (searchText !== '')) {
                router.push(`/library/search/${encodeURIComponent(searchText)}`)
              }
            }}
            className='flex items-center'
          >
            <button type='submit' className='search-btn border-0 padding-0 bg-inherit'>
              <HiSearch />
            </button>
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus={true}
              placeholder='Buscar'
              type="text"
              className='bg-inherit outline-none transition-all ease-in-out h-full'
            />
          </form>
        </div>
      </div>
      <div className={`flex flex-col md:flex-row gap-5`}>
        <div className="featured w-full md:w-1/2">
            <ArticleCard data={getLatests()[0]} type={ArticleType.FEATURED} />
        </div>
        <div className="side flex flex-col w-full md:w-1/2 gap-5">
            <div>
                <ArticleCard data={getLatests()[1]} type={ArticleType.REDUCED} />
            </div>
            <div>
                <ArticleCard data={getLatests()[2]} type={ArticleType.REDUCED} />
            </div>
            <div>
                <ArticleCard data={getLatests()[3]} type={ArticleType.REDUCED} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default LatestArticles